/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedToTeachWorksheets } from '../models/PaginatedToTeachWorksheets';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ToTeachService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of Worksheets
     * @returns PaginatedToTeachWorksheets logged out successful
     * @throws ApiError
     */
    public listToTeachWorksheets({
        page = 1,
        limit = 10,
        order,
        international,
        query,
        subjectTypesArray,
        ids,
    }: {
        page?: number,
        limit?: number,
        order?: string,
        international?: string,
        query?: string,
        subjectTypesArray?: Array<string>,
        ids?: string,
    }): CancelablePromise<PaginatedToTeachWorksheets> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/to_teach/worksheets',
            query: {
                'page': page,
                'limit': limit,
                'order': order,
                'international': international,
                'query': query,
                'subject_types[]': subjectTypesArray,
                'ids': ids,
            },
        });
    }
}
