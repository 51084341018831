/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiAssistantAvatarsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Assistant Avatar
     * @returns any successful
     * @throws ApiError
     */
    public createAssistantAvatar({
        assistantId,
    }: {
        assistantId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/assistants/{assistant_id}/assistant_avatars',
            path: {
                'assistant_id': assistantId,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
