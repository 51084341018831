/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditActionEnum } from '../models/AuditActionEnum';
import type { PaginatedAudits } from '../models/PaginatedAudits';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuditsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of Audits
     * @returns PaginatedAudits successful audits for item
     * @throws ApiError
     */
    public listAudits({
        page = 1,
        limit = 10,
        auditAction,
        schoolClassId,
        pupilId,
        itemId,
    }: {
        page?: number,
        limit?: number,
        auditAction?: AuditActionEnum,
        schoolClassId?: string,
        pupilId?: string,
        itemId?: string,
    }): CancelablePromise<PaginatedAudits> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/audits',
            query: {
                'page': page,
                'limit': limit,
                'auditAction': auditAction,
                'schoolClassId': schoolClassId,
                'pupilId': pupilId,
                'itemId': itemId,
            },
            errors: {
                401: `unauthorized`,
                404: `not found for school_class without access`,
            },
        });
    }
}
