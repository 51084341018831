import { useApiClient } from "@/composables/useApiClient";
import { SchoolClassTypeEnum } from "@/services/fobizzToolsApi";

export const useSession = () => {
  const { sessions: sessionsService, users: usersService } = useApiClient();
  const { apiClient: platformApiClient } = usePlatformApiClient();

  const userStore = useUserStore();

  const queryClient = inject("queryClient") as ReturnType<
    typeof useQueryClient
  >;

  const refetchUser = async () => {
    try {
      const newUser = await usersService.getCurrentUser();

      // replace user with fresh data to ensure that any stale data is removed
      // => https://pinia.vuejs.org/core-concepts/state.html#Replacing-the-state
      userStore.$state = {
        ...userStore.$state,
        user: newUser,
      };

      if (newUser.visitor) {
        userStore.visitorToken = newUser.id;
      } else {
        userStore.visitorToken = undefined;
      }

      if (newUser.isPlatformUser) {
        await refetchPlatformUser();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const refetchPlatformUser = async () => {
    try {
      const newPlatformUser = await platformApiClient.users.getCurrentUser();

      userStore.$state = {
        ...userStore.$state,
        platformUser: newPlatformUser,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const router = useRouter();

  const logout = async (redirectBack: false | string = false) => {
    try {
      const isPupil = userStore.isPupil;
      const isOneTime =
        userStore.user?.schoolClassType === SchoolClassTypeEnum.ONETIME;
      const isPlatformUser = userStore.user?.isPlatformUser;

      await sessionsService.deleteSession();

      userStore.reset();

      queryClient.clear();

      await refetchUser();

      if (isPlatformUser) {
        logoutPlatform(redirectBack);
      } else if (!redirectBack) {
        if (isPupil) {
          if (isOneTime) {
            router.push({ name: "pupil-logout-success" });
          } else {
            router.push({ name: "go-login" });
          }
        } else {
          router.push({ name: "home" });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const urlsStore = useUrlsStore();

  const logoutPlatform = (redirectBack: false | string = false) => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = urlsStore.oauthLogoutUrl;

    const typeField = document.createElement("input");
    typeField.type = "hidden";
    typeField.name = "redirect_uri";
    typeField.value = redirectBack ? redirectBack : window.location.origin;
    form.appendChild(typeField);

    document.body.appendChild(form);

    form.submit();

    document.body.removeChild(form);
  };

  return {
    logout,
    refetchUser,
  };
};
