<script lang="ts">
export default {
  name: "BaseButton",
};
</script>

<script setup lang="ts">
import { type RouterLinkProps } from "vue-router";
import { BaseButtonKindEnum } from "./Button";

export type Props = {
  disabled?: boolean;
  to?: RouterLinkProps["to"];
  kind?: BaseButtonKindEnum[keyof BaseButtonKindEnum]; // material search forms;
  variant?: "dark" | "light" | "primary"; // used for links
  rounded?: boolean;
  circle?: boolean;
  size?: "sm" | "body" | "lg" | undefined;
  weight?: "medium" | "normal" | "semibold" | "bold";
  type?: "button" | "submit" | "reset" | "";
  ariaLabel?: string;
  inline?: boolean;
  underline?: boolean; // used for links
  noPadding?: boolean; // used for links
  shadow?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  to: undefined,
  kind: BaseButtonKindEnum.PRIMARY,
  rounded: false,
  circle: false,
  size: undefined,
  weight: "normal",
  type: "",
  variant: "primary",
  ariaLabel: "",
  inline: false,
  underline: false,
  noPadding: false,
  shadow: false,
});

const classes = computed(() => {
  const classNames = [];
  if (props.kind) {
    classNames.push(`btn-${props.kind}`);
  }

  if (props.size) {
    classNames.push(`btn-${props.size}`);
  }

  if (props.weight) {
    classNames.push(`fw-${props.weight}`);
  }

  if (props.disabled) {
    classNames.push("opacity-50 cursor-not-allowed");
  }

  if (props.rounded) {
    classNames.push("btn-rounded");
  }

  if (props.circle) {
    classNames.push("btn-circle");
  }

  if (props.kind === "link" && !!props.variant && props.variant !== "primary") {
    // without suffix, ".btn-link" defaults to brand-tools/primary color
    classNames.push(`btn-link--${props.variant}`);
  }

  return classNames.join(" ");
});

const componentProps = computed(() => {
  const properties = {
    class: classes.value,
    disabled: props.disabled,
    "aria-label": props.ariaLabel,
  };

  if (props.to) {
    return {
      ...properties,
      to: props.to,
    };
  }

  if (props.type) {
    return {
      ...properties,
      type: props.type,
    };
  }

  return properties;
});

const componentType = computed(() => {
  if (props.to) {
    return "router-link";
  }

  return "button";
});

const cssClasses = computed(() => {
  const classes = ["btn"];

  if (props.inline) {
    classes.push("btn-inline-flex");
  } else {
    classes.push("btn-flex");
  }

  if (props.underline) {
    classes.push("btn-underline");
  }

  if (props.noPadding) {
    classes.push("btn-no-padding");
  }

  return classes.join(" ");
});
</script>

<template>
  <Component :is="componentType" v-bind="componentProps" :class="cssClasses">
    <slot></slot>
  </Component>
</template>
<style scoped lang="scss">
.btn:disabled {
  opacity: 0.3;
}

.btn-semi-rounded {
  --bs-btn-border-radius: 10px;
}

.btn-white {
  --bs-btn-bg: var(--color-white);
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-border-color: var(--color-gray-300);
  --bs-btn-border-width: 2px;
  --bs-btn-hover-bg: var(--color-gray-150);
  --bs-btn-hover-border-color: var(--color-gray-200);
  --bs-btn-active-bg: var(--color-white);
  --bs-btn-active-border-color: var(--color-gray-300);
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgb(80 92 136 / 25%);
  --bs-btn-disabled-bg: var(--color-white);
  --bs-btn-disabled-border-color: var(--color-gray-200);

  &:focus-visible {
    border-color: #a8aec4;
  }
}

.btn-shadow {
  box-shadow: $shadow-brand;
}
</style>
