/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiMessage } from '../models/AiMessage';
import type { AiMessageInput } from '../models/AiMessageInput';
import type { PaginatedAiMessages } from '../models/PaginatedAiMessages';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiMessagesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Message
     * @returns AiMessage successful
     * @throws ApiError
     */
    public createAiMessage({
        ownerType,
        ownerId,
        requestBody,
        force,
    }: {
        ownerType: string,
        ownerId: string,
        requestBody: {
            message?: AiMessageInput;
        },
        force?: boolean,
    }): CancelablePromise<AiMessage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/messages',
            query: {
                'owner_type': ownerType,
                'owner_id': ownerId,
                'force': force,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of AI Messages
     * @returns PaginatedAiMessages successful
     * @throws ApiError
     */
    public listAiMessages({
        ownerType,
        ownerId,
        page = 1,
        limit = 10,
    }: {
        ownerType: string,
        ownerId: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedAiMessages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/messages',
            query: {
                'owner_type': ownerType,
                'owner_id': ownerId,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Message
     * @returns void
     * @throws ApiError
     */
    public deleteAiMessage({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/messages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * AI Message Details
     * @returns AiMessage successful
     * @throws ApiError
     */
    public getAiMessage({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiMessage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/messages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
